import React from "react";
import "./Uploader.css";
import Dropzone, {
  IFileWithMeta,
  IUploadParams,
} from "@jeffreyca/react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import uuid from "uuid";

import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";

import { config } from "../../config";

import { convertSize, calcConsumption, i18n } from "../../utils";
import { connect } from "react-redux";

import { appAction, fileAction, transferAction } from "../../Store";

import {
  UploaderInputComponent,
  LayoutComponent,
  DepositCreateComponent,
} from "./Components";

import { LoaderComponent } from "../";
import transferApi from "../../Api/transferApi";
import { isMobile, isTablet } from "react-device-detect";
import { Go } from "../../Constants";

const limitsInGo = [2, 20, 20];

const { getDroppedOrSelectedFiles } = require("html5-file-selector");

interface TransferInfos {
  auto_remove: boolean;
  transfer_name?: string;
  message?: string;
  recipients?: string;
  sender?: string;
  transfer_password?: string;
}

interface Props {
  user: any;
  app: any;
  dispatch: Function;
  file: IFileWithMeta[];
  transfer: TransferInfos & any;
  transferConfig?: any | undefined;
  children?: any | undefined;
  transfer_type?: number | undefined;
  show_deposit?: boolean;
}

interface State {
  isModalOpen: boolean;
  files: IFileWithMeta[];
  messages: string[];
  isSubmited: boolean;
  isUploadFinished: boolean;
  totalSize: number;
  pgTransferId: number;
  isFilesLoading: boolean;
  totalLoaded: number;
  currentFileLoaded: number;
  successCopyLink: boolean;
  processingFiles: number;
  loadedFiles: number;
  transferHeaders: any;
  progress: {
    last: {
      timestamp: number;
      loaded: number;
    };
    current: {
      timestamp: number;
      loaded: number;
    };
  };
  isFetchingProgress: boolean;
  online: boolean;
}

const fileInterval: number = 10;
class UploaderComponent extends React.Component<Props, State> {
  filesUploaded: number[] = [];
  filesNb: number = 0;
  counter: number = 0;
  testTime = 0;
  filesCount: number = 0;
  tmpFilesCount: number = 0;
  transferId: string;
  apiUrl: string;
  startTime: number;
  succededFiles: number;
  directories: any;
  progress: {
    last: {
      timestamp: number;
      loaded: number;
    };
    current: {
      timestamp: number;
      loaded: number;
    };
  };
  divider: number = 1;
  timer: any;
  lastRealProgress: number = 0;
  tmpProgress: number = 0;
  apiProgressInterval: any = null;
  filePercents: any = {};
  /***
   * Constructor of UploaderComponent
   * @param props: Props
   */
  constructor(props: Props) {
    super(props);
    if (this.props.file.length) this.props.dispatch(fileAction.purgeFile());

    // if (this.props.transfer !== {})
    //   this.props.dispatch(transferAction.purgeInfos({}));

    if (this.props.transfer.isLocked)
      this.props.dispatch(transferAction.lockTransfer(false));

    this.transferId = uuid.v4();
    this.apiUrl = config.apiUrl;
    this.succededFiles = 0;
    this.directories = {};
    this.progress = {
      last: {
        timestamp: 0,
        loaded: 0,
      },
      current: {
        timestamp: 0,
        loaded: 0,
      },
    };

    this.state = {
      isModalOpen: false,
      isFilesLoading: false,
      pgTransferId: 0,
      files: [],
      messages: [],
      isSubmited: false,
      isUploadFinished: false,
      totalSize: 0,
      totalLoaded: 0,
      currentFileLoaded: 0,
      successCopyLink: false,
      processingFiles: 0,
      loadedFiles: 0,
      transferHeaders: undefined,
      progress: this.progress,
      isFetchingProgress: false,
      online: navigator.onLine,
    };

    this.startTime = 0;

    window.addEventListener("offline", this.handleConnexionLost);
    window.addEventListener("online", this.handleConnexionRetreive);
  }

  /***
   * @param meta: IFileWithMeta
   * @return IUploadParams | Promise<IUploadParams>
   */
  getUploadParams = ({
    file,
    meta,
  }: IFileWithMeta & any): IUploadParams | Promise<IUploadParams> => {
    return { url: "" };
  };

  setFilesLoading = (chosenFiles: any) =>
    new Promise((resolve, reject) => {
      this.setState(
        {
          processingFiles: this.state.processingFiles + chosenFiles.length,
          isFilesLoading: true,
        },
        () => setTimeout(() => resolve(chosenFiles), 100)
      );
    });

    getFilesFromEvent = (e: any) => {
      this.testTime = Date.now();
      let allChosenFiles: any;
      return new Promise((resolve) => {
        this._dragEnd();
        
        getDroppedOrSelectedFiles(e)
          .then((chosenFiles: any) => {
            allChosenFiles = chosenFiles;
            this.setState({ isModalOpen: true, isFilesLoading: true });
            return this.setFilesLoading(chosenFiles);
          })
          .then((chosenFiles: any) => {  // Ajout du type explicite ici
            resolve(
              allChosenFiles.map((f: any) => {
                f.name = escape(f.name);
                f.fullPath = f.fullPath ? escape(f.fullPath) : f.fullPath;
    
                Object.defineProperty(f.fileObject, "name", {
                  writable: true,
                  value: escape(f.fileObject.name),
                });
    
                if (f.webkitRelativePath) {
                  f.fileObject.fullPath = f.webkitRelativePath;
                } else {
                  f.fileObject.fullPath =
                    f.fullPath[0] !== "/" ? f.fullPath : f.fullPath.substr(1);
                }
    
                return f.fileObject;
              })
            );
          });
      }) as any;
    };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  onProgress = async (XHRProgress: any, metaId: string, strIndex: string) => {
    if (this.props.transfer.purging) {
      window.stop();
      return;
    }
    // console.log(XHRProgress, metaId, strIndex);

    if (!this.state.online) return;
    // strIndex = `K_${strIndex}`;
    const oneProgress = XHRProgress.loaded;
    this.filePercents[metaId][strIndex] =
      oneProgress / (XHRProgress.total * 100);
    this.filePercents[`${metaId}_loaded`][strIndex] = oneProgress;
    this.filePercents[`${metaId}_total`][strIndex] = XHRProgress.total;

    const arK = Object.values(this.filePercents[`${metaId}_loaded`]);
    // prettier-ignore
    //@ts-ignore
    const realProgress: number = (arK.length) ? arK.reduce((n1: any, n2: any) => n1 + n2) : 0;

    const progress = {
      last: this.state.progress.current,
      current: {
        loaded: realProgress,
        timestamp: XHRProgress.timeStamp,
      },
    };
    this.setState(
      {
        ...this.state,
        currentFileLoaded: realProgress,
        progress,
      },
      () => {
        const loaded = this.state.totalLoaded + this.state.currentFileLoaded;
        const now = new Date().getTime();
        this.props.dispatch(
          transferAction.setInfos({
            ...this.props.transfer,
            loaded,
            progress,
            consumption: calcConsumption.forUpload(
              loaded,
              now - this.props.transfer.startTime
            ),
          })
        );
      }
    );

    //${convertSize(this.state.totalLoaded + this.state.currentFileLoaded)}
  };

  handleSubmit = (files: any, allFiles: any) => {
    allFiles.forEach((f: any) => f.remove());
    this.setState({ files: [] });
  };

  handleConnexionLost = () => {
    this.setState({ ...this.state, online: false });
  };

  handleConnexionRetreive = () => {
    // prettier-ignore
    //@ts-ignore
    setTimeout(() => this.setState({...this.state, online: true}), window.retryDelay);
  };

  handleChangeStatus = (
    { meta, file, xhr }: any,
    status: any,
    allFiles: IFileWithMeta[]
  ) => {
    if (status === "error_upload") console.log(meta, xhr);

    if (this.state.isUploadFinished) return;

    if (("ready" === status || "removed" === status) && this.props.file) {
      this.filesCount += 1;

      if (this.filesCount - this.tmpFilesCount === fileInterval) {
        this.tmpFilesCount += fileInterval;
        this.setState({ ...this.state, loadedFiles: this.filesCount });
      }

      if (
        this.state.processingFiles === this.filesCount ||
        "removed" === status
      ) {
        let totalSize = 0;
        allFiles.forEach((f: IFileWithMeta) => {
          totalSize += "removed" !== f.meta.status ? f.meta.size : 0;
        });

        this.setState(
          { totalSize, loadedFiles: this.filesCount, processingFiles: 0 },
          () => {
            this.filesCount = 0;
            this.props.dispatch(fileAction.setFile(allFiles));
            this.props.dispatch(
              transferAction.setInfos({
                ...this.props.transfer,
                totalSize,
                loaded: 0,
              })
            );
          }
        );
      }
    }

    if ("validation_error" === status) {
      alert("Fichier déjà présent");
    }
  };
  handleRestart = (file: IFileWithMeta): void => {};

  handlePause = (file: IFileWithMeta): void => {
    if (file && file.cancel) file.cancel();
  };
  handleRemove = (file: IFileWithMeta): void => {
    if (file && file.remove) file.remove();
  };
  checkFile = (file: IFileWithMeta & any) => {
    return false; // error;
  };
  isValidLimit = () =>
    limitsInGo[this.props.user.role || 0] * Go >= this.state.totalSize;

  handleChange = (evt: any) => {
    if (this.props.transfer.isLocked) {
      return;
    }
    return this.props.dispatch(
      transferAction.setInfos({
        ...this.props.transfer,
        [evt.target.name]: evt.target.checked,
      })
    );
  };

  componentDidMount = () => {
    if (this.props.app.APP_ERROR)
      this.props.dispatch(appAction.setAppError(null));
    window.addEventListener("dragenter", this._dragEnter);
    window.addEventListener("dragover", this._dragEnter);
    this.transferId = uuid.v4();

    // prettier-ignore
    //@ts-ignore
    window.hackResetTransfer = this.resetTransfer;
  };

  _dragEnter = () => {
    window.removeEventListener("dragenter", this._dragEnter);
    window.removeEventListener("dragover", this._dragEnter);

    window.addEventListener("dragend", this._dragEnd);
    window.addEventListener("drop", this._dragEnd);
    window.addEventListener("mouseup", this._dragEnd);

    const input = document.getElementById("input-file");
    const uploaderFull =
      document.getElementsByClassName("uploader drop full")[0];
    const uploaderEmpty = document.getElementsByClassName("uploader drop")[0];

    if (input && input.style.position !== "fixed") {
      input.style.position = "fixed";
    }

    if (uploaderFull) {
      // prettier-ignore
      //@ts-ignore
      uploaderFull.style.backgroundColor = 'rgba(255, 255, 255, .75)';
    }

    if (uploaderEmpty) {
      uploaderEmpty.setAttribute("data-status", "active");
    }
  };

  _dragEnd = () => {
    window.removeEventListener("dragend", this._dragEnd);
    window.removeEventListener("drop", this._dragEnd);
    window.removeEventListener("mouseup", this._dragEnd);

    window.addEventListener("dragenter", this._dragEnter);
    window.addEventListener("dragover", this._dragEnter);

    const input = document.getElementById("input-file");
    const uploaderEmpty = document.getElementsByClassName("uploader drop");

    if (input && input.style.position === "fixed") {
      input.style.position = "relative";
    }

    /*if (uploaderFull[0]) {
      // prettier-ignore
  //@ts-ignore
      uploaderFull[0].style.backgroundColor = 'red';
    }*/

    if (uploaderEmpty[0]) {
      uploaderEmpty[0].removeAttribute("data-status");
    }
  };

  wrapName = (str: string): string =>
    str.length > 53 ? `${str.substr(0, 50)}...` : str;

  renderCustomPreview = ({ meta, fileWithMeta, k }: any) => {
    let { name, status, size } = meta;

    const arKLoaded = this.filePercents[`${meta.id}_loaded`]
      ? Object.values(this.filePercents[`${meta.id}_loaded`])
      : [0];
    // prettier-ignore
    //@ts-ignore
    const nTotal: number = meta.size;
    // prettier-ignore
    //@ts-ignore
    const nLoaded: number = (arKLoaded.length) ? arKLoaded.reduce((n1: any, n2: any) => n1 + n2) : 0;

    const percent = (nLoaded * 100) / nTotal || 0;

    if (!meta || this.state.isUploadFinished || status === "error_validation") {
      return null;
    }

    try {
      name = decodeURIComponent(name);
    } catch (e) {}
    try {
      name = decodeURI(name);
    } catch (e) {}
    try {
      name = unescape(name);
    } catch (e) {}

    const dir_name: string[] = fileWithMeta.file?.fullPath?.split("/");
    dir_name?.pop();

    const OldPreview = () => (
      <div className={"row w-100 mx-auto hover-remove"}>
        <div className={"col-1 py-2"}>
          {dir_name && dir_name.length ? (
            <span className={"wrapper-info"}>
              <MDBIcon
                icon={"info"}
                size={"1x"}
                className={"grey-text hover-info hover-pointer"}
              />
              <label
                className={
                  "col-12 preview label name pl-0 data-info bg-white text-black"
                }
              >
                <strong className={"mr-1"}>{i18n._("file_path_label")}</strong>
                {dir_name.join("/")}/{name}
                <br />
                <strong className={"mr-1"}>{i18n._("file_name_label")}</strong>
                {name}
                <br />
                <strong className={"mr-1"}>{i18n._("file_size_label")}</strong>
                {`${convertSize(size)}`}
              </label>
            </span>
          ) : null}
          {!dir_name || !dir_name.length ? (
            <span className={"wrapper-info"}>
              <MDBIcon
                icon={"info"}
                size={"1x"}
                className={"grey-text hover-info hover-pointer"}
              />
              <label
                className={
                  "col-12 preview label name pl-0 data-info bg-white text-black"
                }
              >
                <strong className={"mr-1"}>
                  {" "}
                  {i18n._("file_name_label")}{" "}
                </strong>{" "}
                {name}
                <br />
                <strong className={"mr-1"}>
                  {i18n._("file_size_label")}{" "}
                </strong>{" "}
                {`${convertSize(size)}`}
              </label>
            </span>
          ) : null}
        </div>

        <div className={"col-10"}>
          <div
            className={`container-fluid ${isMobile && !isTablet ? "pl-0" : ""}`}
          >
            <div className={"row w-100 mx-auto dzu-previewFileName"}>
              <div
                className={`col-12 dzu-previewFileName  ${
                  isMobile && !isTablet ? "px-0" : ""
                }`}
              >
                <label
                  className={
                    "col-12 preview label name pl-0 d-flex justify-content-between"
                  }
                >
                  <span>{this.wrapName(name)}</span>

                  {/*<span>
                      {`(${convertSize(size)})`}
                    </span>*/}

                  <span className={"float-right text-right"}>
                    {percent > 0 && `${percent.toFixed(0)}%`}
                  </span>
                </label>
                <label className={"col-12 preview label name"}>
                  <progress
                    className={"row w-100"}
                    max={100}
                    value={percent}
                  ></progress>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`col-1 my-auto d-flex ${
            isMobile && !isTablet ? "pl-1" : ""
          }`}
          style={{ color: "#444" }}
        >
          {this.props.transfer.isLocked ? null : (
            <MDBIcon
              size={"1x"}
              icon={"times"}
              title={"Supprimer le fichier"}
              className={"hover-pointer mx-1 data-hover"}
              onClick={() => this.handleRemove(fileWithMeta)}
            />
          )}
        </div>
      </div>
    );

    return (
      <div
        key={k}
        className={
          "preview container container-fluid w-100 hover-info bg-white"
        }
      >
        <OldPreview />
      </div>
    );
  };

  getSpeed = (): string => {
    let timing =
      (this.progress.current.timestamp - this.progress.last.timestamp) / 1000;
    let amount = this.progress.current.loaded - this.progress.last.loaded;

    if (
      !this.progress.current.timestamp ||
      !this.progress.last.timestamp ||
      amount / timing < 0
    ) {
      if (!this.state.totalLoaded && !this.state.currentFileLoaded) {
        return "";
      }
    }

    timing = (Date.now() - this.startTime) / 1000;
    amount = this.state.totalLoaded + this.state.currentFileLoaded;

    return `${convertSize(amount / timing)}/s`;
  };

  getPercent = (): string => {
    const loaded = this.state.totalLoaded + this.state.currentFileLoaded;
    const total = this.state.totalSize;

    const percent = (loaded * 100) / total;

    return `${(percent > 100 ? 100 : percent).toFixed(0)} %`;
  };

  resetTransfer = async () => {
    if (this.props.file && this.props.file.length) {
      await this.props.file.forEach(async (fWMita: IFileWithMeta) => {
        //await fWMita.pause()
        await fWMita.cancel();
        await fWMita.remove();
      });
    }

    this.props.dispatch(fileAction.purgeFile());
    this.props.dispatch(transferAction.purgeInfos());
    this.transferId = "";

    return true;
  };

  render = () => {
    const { children } = this.props;
    const suffix = this.props.transfer.isUploadFinished
      ? "w-100 no-padding"
      : "mx-auto";
    const colClass = !!this.props.file.length
      ? `col-md-12 col-lg-12`
      : "col-12";
    const colClassSuffix =
      this.props.transfer.isUploadFinished || this.props.transfer.isLocked
        ? "uploading"
        : "";
    const { loadedFiles, processingFiles } = this.state;

    if (this.props.transfer.purging) {
      return null;
    }

    return (
      //this.props.file && this.props.file.length
      <MDBContainer className={`uploader h-100 fv_uploader_container`}>
        <MDBRow>
          <MDBCol
            lg={"12"}
            xl={"12"}
            className={`uploader h-100 mx-auto offset-md-0 ${
              this.props.file && this.props.file.length ? "full" : "empty"
            }`}
          >
            {!!children && !(processingFiles > loadedFiles) && (
              <MDBContainer>
                <MDBRow className={"w-75 mx-auto"}>
                  <MDBCol size={"12"} className={"px-0"}>
                    {children}
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            )}
            {!this.props.transfer?.purging && (
              <Dropzone
                classNames={{
                  dropzone:
                    this.props.file.length && !(processingFiles > loadedFiles)
                      ? `${colClass} uploader drop full ${suffix} ${colClassSuffix} fv_uploader_full`
                      : `${colClass} uploader drop empty px-0 mx-auto fv_uploader_empty`,
                  inputLabel: "uploader input",
                }}
                validate={this.checkFile}
                autoUpload={false}
                accept="*"
                getUploadParams={this.getUploadParams}
                // prettier-ignore
                //@ts-ignore
                LayoutComponent={this.props.transfer?.totalSize ? ({...props}) => <LayoutComponent submitCb={this.onSubmitCb} resetCb={this.resetTransfer} {...props} role={this.props?.user?.role} /> : undefined}
                PreviewComponent={
                  this.props.transfer.totalSize
                    ? this.renderCustomPreview
                    : undefined
                }
                // prettier-ignore
                //@ts-ignore
                InputComponent={({accept, onFiles, getFilesFromEvent}) => (
                    <UploaderInputComponent
                      percent={(loadedFiles * 100) / processingFiles}
                      accept={accept}
                      onFiles={onFiles}
                      getFilesFromEvent={getFilesFromEvent}
                      isFilesLoading={(processingFiles > loadedFiles)}
                    >

                      <DepositCreateComponent visible={this.props?.show_deposit}/>

                    </UploaderInputComponent>
                )}
                getFilesFromEvent={this.getFilesFromEvent}
                onChangeStatus={this.handleChangeStatus}
                canCancel={true}
                //initialFiles={[new File(['sdfsdf'], 'test')]}
                styles={{
                  dropzoneActive: {
                    borderColor: "#fff",
                    backgroundColor: "rgba(255, 255, 255, .6)",
                  },
                  dropzoneReject: {
                    borderColor: "red",
                    backgroundColor: "rgba(255, 155, 155, .6)",
                  },
                  preview: { display: "none" },
                  previewImage: { display: "none" },
                }}
              />
            )}
            <div
              className={
                "mb-3 px-2 uploader-tooltip bg-dark text-white mbox fv_uploader_tooltip"
              }
            >
              <small>
                Cliquez sur le lien pour le copier ou sur l'icône pour le
                partager par email
                <span className={"triangle dark fv_triangle_dark"}></span>
              </small>
            </div>

            {!(this.state.online || this.props.app.SET_APP_ERROR) && (
              <div
                className={
                  "position-fixed h-100 w-100 text-white text-center network-container fv_network_container"
                }
              >
                <LoaderComponent
                  text={i18n._("waiting_network_connexion_label")}
                  type={"network_error"}
                />
              </div>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };

  onSubmitCb = async (transfer: any) => {
    try {
      // REAL SUBMIT CALLBACK
      const fileWMeta: IFileWithMeta = this.props.file[this.filesNb];
      this.filePercents[fileWMeta.meta.id] = {};
      this.filePercents[`${fileWMeta.meta.id}_loaded`] = {};
      this.filePercents[`${fileWMeta.meta.id}_total`] = {};

      // prettier-ignore
      //@ts-ignore
      const {size, fullPath, webkitRelativePath, name} = fileWMeta.file;
      const presignedPostData = await transferApi.getPresignedPostData(
        {
          size,
          fullPath: `${
            fullPath || webkitRelativePath || name || fileWMeta.meta.name
          }`,
          name: fileWMeta.meta.name,
          type: fileWMeta.meta.type,
        },
        transfer
      );

      if (presignedPostData?.error) throw presignedPostData?.error;

      const file_id = presignedPostData.UploadId;

      const { file } = fileWMeta;
      await transferApi.uploadFileToS3(
        presignedPostData,
        file,
        (prg: ProgressEvent, strIndex: string) =>
          this.onProgress(prg, fileWMeta.meta.id, strIndex),
        transfer
      );
      this.setState(
        {
          totalLoaded: this.state.totalLoaded + file.size,
          currentFileLoaded: 0,
        },
        () => {
          this.filesUploaded.push(file_id);
          if (++this.filesNb < this.props.file.length) {
            this.onSubmitCb(transfer);
          } else return this.finalizeTransfer(transfer);
        }
      );
    } catch (e) {
      this.props.dispatch(appAction.setAppError(e));
    }
  };

  finalizeTransfer = async (transfer: any) => {
    // console.log(this.filesUploaded)
    this.succededFiles = 0;
    const totalTime = (Date.now() - this.props.transfer.startTime) / 1000;

    const consumption = calcConsumption.forUpload(
      this.state.totalSize,
      totalTime
    );

    const result = await transferApi.finalizeTransfer(
      {
        consumption: { ...consumption, size: this.state.totalSize },
        transfer_size: this.state.totalSize,
      },
      transfer
    );

    this.props.dispatch(
      transferAction.setInfos({
        ...this.props.transfer,
        totalTime,
        isUploadFinished: true,
        link: `${config.ihmUrl}/d/${result.transfer_link}`,
        consumption,
        expiration_date: result.expiration_date,
      })
    );
  };

  componentWillUnmount(): void {
    if (this.props.app.APP_ERROR)
      this.props.dispatch(appAction.setAppError(null));
    window.removeEventListener("offline", this.handleConnexionLost);
    window.removeEventListener("online", this.handleConnexionRetreive);
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user?.user,
  file: state.file,
  transfer: state.transfer.transfers,
  app: state.app,
});

export default connect(mapStateToProps)(UploaderComponent);
